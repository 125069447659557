define('ember-cli-stylist/mixins/shared-stylist', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var keys = Object.keys;
  var create = Object.create;
  // jshint ignore:line
  var _Ember$RSVP = _ember['default'].RSVP;
  var Promise = _Ember$RSVP.Promise;
  var all = _Ember$RSVP.all;
  var race = _Ember$RSVP.race;
  var resolve = _Ember$RSVP.resolve;
  var defer = _Ember$RSVP.defer;
  // jshint ignore:line
  var service = _ember['default'].inject.service;
  // jshint ignore:line
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var typeOf = _ember['default'].typeOf;
  var isPresent = _ember['default'].isPresent;
  // jshint ignore:line
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var merge = _ember['default'].merge;
  // jshint ignore:line
  var a = _ember['default'].A; // jshint ignore:line
  var defaultBindings = ['width', 'minWidth', 'height', 'minHeight', 'maxHeight', 'fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'cursor', 'display'];
  var sizer = function sizer(size) {
    // scalar → rem value
    if (typeOf(size) === 'string' && size.match(/^(\d+)x$/)) {
      size = size.replace(/^(\d+)x$/, '$1' + 'rem');
    }
    return isNaN(Number(size)) ? size : size + 'px';
  };
  var dasherize = function dasherize(thingy) {
    return thingy ? _ember['default'].String.dasherize(thingy) : thingy;
  };

  var htmlSafe = _ember['default'].String.htmlSafe;

  var SharedStylist = _ember['default'].Mixin.create({
    _styleBindings: computed('styleBindings', function () {
      var styleBindings = this.get('styleBindings') || defaultBindings;
      styleBindings = typeOf(styleBindings) === 'string' ? styleBindings.split(',') : styleBindings;
      return styleBindings.map(function (sb) {
        if (sb.indexOf('::') !== -1) {
          sb = { property: sb.split('::')[0], bindTo: sb.split('::')[1] };
        } else {
          sb = { property: sb, bindTo: sb };
        }
        return sb;
      });
    }),
    _init: on('init', function () {
      var _this = this;

      var observerBindings = this.get('_styleBindings');
      observerBindings.map(function (item) {
        _this.addObserver(item.bindTo, _this._setStyle);
      });
      run.schedule('afterRender', function () {
        _this._setStyle();
      });
    }),
    // Because we created the observer dynamically we must take responsibility of
    // removing the observers on exit
    _willDestroyElement: on('willDestroyElement', function () {
      var _this2 = this;

      var observerBindings = this.get('_styleBindings');
      observerBindings.map(function (item) {
        _this2.removeObserver(item.bindTo, _this2, '_setStyle');
      });
    }),
    _setStyle: function _setStyle() {
      var _this3 = this;

      var styleBindings = this.get('_styleBindings');
      var styles = [];
      styleBindings.map(function (style) {
        var cssProp = dasherize(style.property);
        var value = _this3._stylist(cssProp, _this3.get(style.bindTo));
        if (value) {
          styles.push(cssProp + ': ' + value);
        }
      });
      this.set('stylist', htmlSafe(styles.join('; ')));
    },
    /**
     * Provides a per-type styler that allows for some sensible defaults
     * @param  {string} style The style property being evaluated
     * @param  {string} value The suggested value for this style property
     * @return {string}       A mildly processed/improved variant on the input
     */
    _stylist: function _stylist(style, value) {
      switch (style) {
        case 'font-size':
        case 'padding':
        case 'margin':
        case 'width':
        case 'height':
        case 'min-width':
        case 'max-width':
          return sizer(value);
        default:
          if (a(['undefined', 'null']).includes(typeOf(value))) {
            return null;
          }
          return value;
      }
    }

  });

  SharedStylist[_ember['default'].NAME_KEY] = 'Shared Stylist';
  exports['default'] = SharedStylist;
});