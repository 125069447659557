define('ember-json-schema-document/models/property', ['exports', 'ember-json-schema-document/utils/get-properties', 'ember-json-schema-document/utils/build-default-value-for-type', 'ember-json-schema-document/utils/check-validity'], function (exports, _emberJsonSchemaDocumentUtilsGetProperties, _emberJsonSchemaDocumentUtilsBuildDefaultValueForType, _emberJsonSchemaDocumentUtilsCheckValidity) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var Property = (function () {
    function Property(property, _parentProperty, _key, _schemaStack) {
      _classCallCheck(this, Property);

      if (!property) {
        throw new Error('You must provide a property definition to the `Property` constructor.');
      }

      if (_parentProperty && !_key) {
        throw new Error('You must provide a property `key` to the `Property` constructor when a `parentProperty` exists.');
      }

      this._key = _key;
      this._property = property;
      this._parentProperty = _parentProperty;
      this._schemaStack = _schemaStack;
    }

    _createClass(Property, [{
      key: 'getChildProperty',
      value: function getChildProperty(key) {
        return this.properties[key];
      }
    }, {
      key: 'resolveURI',
      value: function resolveURI(uri) {
        var hashIdx = uri.indexOf('#');

        if (hashIdx === -1) {
          return this._property.properties[uri];
        }

        if (hashIdx === 0) {
          return this._schemaStack[0].resolveURI(uri.substr(1));
        }

        throw new Error('Only relative root references are implemented');
      }
    }, {
      key: 'buildDefaultValue',
      value: function buildDefaultValue() {
        return (0, _emberJsonSchemaDocumentUtilsBuildDefaultValueForType['default'])(this._property.type);
      }
    }, {
      key: 'isValid',
      value: function isValid(object) {
        return (0, _emberJsonSchemaDocumentUtilsCheckValidity['default'])(this, object);
      }
    }, {
      key: 'type',
      get: function get() {
        return this._property.type;
      }
    }, {
      key: 'displayProperties',
      get: function get() {
        return this._property.displayProperties;
      }
    }, {
      key: 'default',
      get: function get() {
        return this._property['default'];
      }
    }, {
      key: 'readonly',
      get: function get() {
        return this._property.readonly || false;
      }
    }, {
      key: 'title',
      get: function get() {
        return this._property.title;
      }
    }, {
      key: 'description',
      get: function get() {
        return this._property.description;
      }
    }, {
      key: 'items',
      get: function get() {
        return this._property.items;
      }
    }, {
      key: 'properties',
      get: function get() {
        if (this._property.properties) {
          return (0, _emberJsonSchemaDocumentUtilsGetProperties['default'])(this, this._property.properties);
        }

        return null;
      }
    }, {
      key: 'validValues',
      get: function get() {
        if (this.items && this.items['enum']) {
          return this.items['enum'];
        }

        return this._property['enum'];
      }
    }, {
      key: 'required',
      get: function get() {
        return this._property.required || [];
      }
    }, {
      key: 'dependencies',
      get: function get() {
        return this._property._dependencies || {};
      }
    }, {
      key: 'parentProperty',
      get: function get() {
        return this._parentProperty;
      }
    }, {
      key: 'hasParentProperty',
      get: function get() {
        return !!this._parentProperty;
      }
    }, {
      key: 'key',
      get: function get() {
        return this._key;
      }
    }, {
      key: 'isDependentProperty',
      get: function get() {
        return this.dependsOnProperties.length > 0;
      }
    }, {
      key: 'hasDependentProperties',
      get: function get() {
        return this.dependentProperties.length > 0;
      }
    }, {
      key: 'dependsOnProperties',
      get: function get() {
        var myKey = this.key;
        var dependsOn = [];

        if (this.hasParentProperty && this.parentProperty.dependencies) {
          var dependencies = this.parentProperty.dependencies[myKey];

          for (var key in dependencies) {
            var values = dependencies[key];

            if (!Array.isArray(values)) {
              values = [values];
            }

            dependsOn.push({
              values: values, property: this.parentProperty.getChildProperty(key)
            });
          }
        }

        return dependsOn;
      }
    }, {
      key: 'dependentProperties',
      get: function get() {
        var myKey = this.key;
        var dependents = [];

        if (this.hasParentProperty && this.parentProperty.dependencies) {
          var dependencies = this.parentProperty.dependencies;

          for (var key in dependencies) {
            if (Object.keys(dependencies[key]).indexOf(myKey) > -1) {
              dependents.push(this.parentProperty.getChildProperty(key));
            }
          }
        }

        return dependents;
      }
    }, {
      key: 'documentPath',
      get: function get() {
        if (this.hasParentProperty) {
          if (this.parentProperty.documentPath) {
            return [this.parentProperty.documentPath, this.key].join('.');
          } else {
            return this.key;
          }
        }

        return '';
      }
    }, {
      key: 'schemaStack',
      get: function get() {
        return this._schemaStack;
      }
    }]);

    return Property;
  })();

  exports['default'] = Property;
});