define('ember-scrollable/components/ember-scrollable', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'ember-scrollable/templates/components/ember-scrollable', 'ember-scrollable/classes/scrollable'], function (exports, _ember, _emberComponentInboundActionsInboundActions, _emberScrollableTemplatesComponentsEmberScrollable, _emberScrollableClassesScrollable) {
  'use strict';

  var _Ember$run = _ember['default'].run;
  var scheduleOnce = _Ember$run.scheduleOnce;
  var debounce = _Ember$run.debounce;
  var bind = _Ember$run.bind;
  var computed = _ember['default'].computed;
  var $ = _ember['default'].$;
  var isPresent = _ember['default'].isPresent;

  var hideDelay = _ember['default'].testing ? 16 : 1000;

  var scrollbarSelector = '.tse-scrollbar';
  var handleSelector = '.drag-handle';
  var scrollContentSelector = '.tse-scroll-content';
  var contentSelector = '.tse-content';

  exports['default'] = _ember['default'].Component.extend(_emberComponentInboundActionsInboundActions['default'], {
    layout: _emberScrollableTemplatesComponentsEmberScrollable['default'],
    classNameBindings: [':ember-scrollable', ':tse-scrollable', 'horizontal:horizontal:vertical'],

    horizontal: false,
    autoHide: true,
    scrollBuffer: 50,
    scrollTo: null,
    _scrollTo: null,

    selector: computed('elementId', function () {
      return '#' + this.elementId;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.setupResize();
      this.measureScrollbar();
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupElements();

      if (this.get('autoHide')) {
        this.on('mouseEnter', this, this.showScrollbar);
      }

      this._handleElement.on('mousedown', bind(this, this.startDrag));
      this._scrollbarElement.on('mousedown', bind(this, this.jumpScroll));
      this._scrollContentElement.on('scroll', bind(this, this.scrolled));

      scheduleOnce('afterRender', this, this.setupScrollbar);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var oldOffset = this.get('_scrollTo');
      var newOffset = this.get('scrollTo');

      if (oldOffset !== newOffset) {
        this.set('_scrollTo', newOffset);
        this.scrollToPosition(newOffset);
      }
    },

    measureScrollbar: function measureScrollbar() {

      /**
       * Calculate scrollbar width
       *
       * Original function by Jonathan Sharp:
       * http://jdsharp.us/jQuery/minute/calculate-scrollbar-width.php
       * Updated to work in Chrome v25.
       */
      function scrollbarWidth() {
        // Append a temporary scrolling element to the DOM, then measure
        // the difference between between its outer and inner elements.
        var tempEl = $('<div class="scrollbar-width-tester" style="width:50px;height:50px;overflow-y:scroll;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div>');
        $('body').append(tempEl);
        var width = $(tempEl).innerWidth();
        var widthMinusScrollbars = $('div', tempEl).innerWidth();
        tempEl.remove();
        // On OS X if the scrollbar is set to auto hide it will have zero width. On webkit we can still
        // hide it using ::-webkit-scrollbar { width:0; height:0; } but there is no moz equivalent. So we're
        // forced to sniff Firefox and return a hard-coded scrollbar width. I know, I know...
        if (width === widthMinusScrollbars && navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
          return 17;
        }
        return width - widthMinusScrollbars;
      }

      this._scrollbarWidth = scrollbarWidth();
    },

    setupScrollbar: function setupScrollbar() {
      var scrollbar = this.createScrollbar();

      this.set('scrollbar', scrollbar);

      this.scrollToPosition(this.get('scrollTo'));
      this.checkScrolledToBottom();

      if (scrollbar.isNecessary) {
        this.showScrollbar();
      }
    },

    setupElements: function setupElements() {
      this._scrollContentElement = this.$('' + scrollContentSelector);
      this._scrollbarElement = this.$(scrollbarSelector + ':first');
      this._handleElement = this.$(handleSelector + ':first');
      this._contentElement = this.$(contentSelector + ':first');
    },

    setupResize: function setupResize() {
      var _this = this;

      this._resizeHandler = function () {
        debounce(_this, _this.resizeScrollbar, 16);
      };

      window.addEventListener('resize', this._resizeHandler, true);
    },

    createScrollbar: function createScrollbar() {
      if (this.get('isDestroyed')) {
        return;
      }

      var ScrollbarClass = this.get('horizontal') ? _emberScrollableClassesScrollable.Horizontal : _emberScrollableClassesScrollable.Vertical;

      return new ScrollbarClass({
        scrollContentElement: this._scrollContentElement,
        scrollbarElement: this._scrollbarElement,
        handleElement: this._handleElement,
        contentElement: this._contentElement,

        width: this.$().width(),
        height: this.$().height(),
        scrollbarWidth: this._scrollbarWidth
      });
    },

    startDrag: function startDrag(e) {
      // Preventing the event's default action stops text being
      // selectable during the drag.
      e.preventDefault();

      this.get('scrollbar').startDrag(e);

      this.on('mouseMove', this, this.drag);
      this.on('mouseUp', this, this.endDrag);
    },

    /**
    * Drag scrollbar handle
    */
    drag: function drag(e) {
      e.preventDefault();

      this.get('scrollbar').drag(e);
    },

    endDrag: function endDrag() {
      this.off('mouseMove', this, this.drag);
      this.off('mouseUp', this, this.endDrag);
    },

    jumpScroll: function jumpScroll(e) {
      // If the drag handle element was pressed, don't do anything here.
      if (e.target === this._handleElement[0]) {
        return;
      }

      this.get('scrollbar').jumpScroll(e);
    },

    scrolled: function scrolled(event) {
      this.get('scrollbar').update();
      this.showScrollbar();

      this.checkScrolledToBottom();

      this.sendScroll(event);
    },

    checkScrolledToBottom: function checkScrolledToBottom() {
      var scrollBuffer = this.get('scrollBuffer');

      if (this.get('scrollbar').isScrolledToBottom(scrollBuffer)) {
        debounce(this, this.sendScrolledToBottom, 100);
      }
    },

    sendScrolledToBottom: function sendScrolledToBottom() {
      this.sendAction('onScrolledToBottom');
    },

    sendScroll: function sendScroll(event) {
      if (this.get('onScroll')) {
        this.sendAction('onScroll', this.get('scrollbar').scrollOffset(), event);
      }
    },

    scrollToPosition: function scrollToPosition(offset) {
      offset = Number.parseInt(offset, 10);

      if (Number.isNaN(offset)) {
        return;
      }

      var scrollbar = this.get('scrollbar');
      if (isPresent(scrollbar)) {
        scrollbar.scrollTo(offset);
      }
    },

    resizeScrollbar: function resizeScrollbar() {
      var scrollbar = this.get('scrollbar');
      if (!scrollbar) {
        return;
      }

      scrollbar = this.createScrollbar();
      this.set('scrollbar', scrollbar);

      this.showScrollbar();
    },

    showScrollbar: function showScrollbar() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('showHandle', true);

      if (!this.get('autoHide')) {
        return;
      }

      debounce(this, this.hideScrollbar, hideDelay);
    },

    hideScrollbar: function hideScrollbar() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('showHandle', false);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$().off('transitionend webkitTransitionEnd', this._resizeHandler);
      window.removeEventListener('resize', this._resizeHandler, true);
    },

    actions: {

      /**
       * Update action should be called when size of the scroll area changes
       */
      recalculate: function recalculate() {
        this.resizeScrollbar();
      },

      /**
       * Can be called when scrollbars changes as a result of value change,
       *
       * for example
       * ```
       * {{#as-scrollable as |scrollbar|}}
       *   {{#each (compute scrollbar.update rows) as |row|}}
       *     {{row.title}}
       *   {{/each}}
       * {{/as-scrollable}}
       * ```
       */
      update: function update(value) {
        scheduleOnce('afterRender', this, this.resizeScrollbar);
        return value;
      },

      /**
       * Scroll Top action should be called when when the scroll area should be scrolled top manually
       */
      scrollTop: function scrollTop() {
        this.set('scrollTo', 0);
      }
    }
  });
});