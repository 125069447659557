define('ember-json-schema-views/mixins/components/schema-field-initializer', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      var key = this.get('key');
      var document = this.get('document');
      var defaultValue = this.get('property.default');
      var initialValue = document.get(key) || defaultValue || '';

      this.set('value', initialValue);
      document.set(key, initialValue);
    },

    getCurrentValue: function getCurrentValue() {
      this.$('input').val();
    },

    disabled: _ember['default'].computed('property.readonly', function () {
      if (this.get('property.readonly')) {
        return 'disabled';
      }

      return false;
    }),

    actions: {
      update: function update(value) {
        if (typeof value === 'undefined') {
          value = this.getCurrentValue();
        }

        var document = this.get('document');
        var key = this.get('key');

        document.set(key, value);
        this.set('value', value);
        this.sendAction('changed', value);
      }
    }
  });
});