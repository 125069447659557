define('ember-json-schema-views/components/schema-field-select/component', ['exports', 'ember', 'ember-json-schema-views/mixins/components/schema-field-initializer'], function (exports, _ember, _emberJsonSchemaViewsMixinsComponentsSchemaFieldInitializer) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports['default'] = _ember['default'].Component.extend(_emberJsonSchemaViewsMixinsComponentsSchemaFieldInitializer['default'], {
    init: function init() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties(['key', 'property', 'document']);

      var key = _getProperties.key;
      var property = _getProperties.property;
      var document = _getProperties.document;

      if (typeof document.get(key) !== 'undefined' && document.get(key) !== '') {
        return;
      }

      if (!_ember['default'].getWithDefault(property, 'displayProperties.prompt', false)) {
        // No value set and no prompt set.  We should select the first value

        var _property$validValues = _slicedToArray(property.validValues, 1);

        var initialValue = _property$validValues[0];

        document.set(key, initialValue);
        this.set('value', initialValue);
      }
    },

    classNames: ['schema-field-component', 'schema-field-select'],
    getCurrentValue: function getCurrentValue() {
      return this.$('select').val();
    }
  });
});