define('ember-json-schema-views/components/schema-field-checkbox/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['schema-field-component', 'schema-field-checkbox'],
    init: function init() {
      this._super.apply(this, arguments);
      var key = this.get('key');
      var document = this.get('document');
      var defaultValue = this.get('property.default');
      var initialValue = null;
      var documentValue = document.get(key);

      if (typeof defaultValue !== 'undefined') {
        initialValue = defaultValue;
      }

      if (typeof documentValue !== 'undefined') {
        initialValue = documentValue;
      }

      if (initialValue !== null) {
        this.set('value', initialValue);
        document.set(key, initialValue);
      }
    },

    actions: {
      update: function update(values) {
        var _getProperties = this.getProperties('document', 'key');

        var document = _getProperties.document;
        var key = _getProperties.key;

        document.set(key, values);
        this.set('value', values);
        this.sendAction('changed', values);
      }
    }
  });
});