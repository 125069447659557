define('ember-json-schema-document/utils/get-properties', ['exports', 'ember-json-schema-document/models/schema', 'ember-json-schema-document/models/property'], function (exports, _emberJsonSchemaDocumentModelsSchema, _emberJsonSchemaDocumentModelsProperty) {
  'use strict';

  exports['default'] = function (object, rawProperties) {
    if (!(object instanceof _emberJsonSchemaDocumentModelsSchema['default']) && !(object instanceof _emberJsonSchemaDocumentModelsProperty['default'])) {
      throw new Error('You must provide a schema or a property object to get the properties for.');
    }

    if (!object._properties) {
      var properties = object._properties = {};
      var keys = Object.keys(rawProperties);

      for (var i = 0, l = keys.length; i < l; i++) {
        var key = keys[i];
        var rawProperty = rawProperties[key];

        while (rawProperty.$ref) {
          rawProperty = object.resolveURI(rawProperty.$ref);
        }

        properties[key] = new _emberJsonSchemaDocumentModelsProperty['default'](rawProperty, object, key, object.schemaStack);
      }
    }

    return object._properties;
  };
});