define('ember-functional-helpers/helpers/sort-by', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var Helper = _ember['default'].Helper;
  var sort = _ember['default'].computed.sort;
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var isArray = _ember['default'].isArray;
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  var observer = _ember['default'].observer;
  var set = _ember['default'].set;

  exports['default'] = Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref);

      var array = _ref2[0];

      var sortProps = _ref2.slice(1);

      set(this, 'array', array);
      set(this, 'sortProps', sortProps);

      return get(this, 'content');
    },

    array: null,
    sortProps: null,

    sortPropsDidChange: observer('sortProps', function () {
      var sortProps = get(this, 'sortProps');

      if (isEmpty(sortProps)) {
        defineProperty(this, 'content', []);
      }

      defineProperty(this, 'content', sort('array', 'sortProps'));
    }),

    contentDidChange: observer('content', function () {
      this.recompute();
    })
  });
});