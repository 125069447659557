define('ember-json-editor-for/components/json-editor-for/component', ['exports', 'ember', 'ember-json-editor-for/components/json-editor-for/template', 'jsoneditor', 'ember-component-inbound-actions/inbound-actions'], function (exports, _ember, _emberJsonEditorForComponentsJsonEditorForTemplate, _jsoneditor, _emberComponentInboundActionsInboundActions) {
  'use strict';

  var K = _ember['default'].K;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;

  var JSONEditorFor = _ember['default'].Component.extend(_emberComponentInboundActionsInboundActions['default'], {

    /**
     * Inputs.
     *
     * See the docs:
     * https://github.com/josdejong/jsoneditor/blob/master/docs/api.md#configuration-options
     */

    // @type {JSON}
    json: null,

    // @type {Object}
    ace: null,

    // @type {Object}
    ajv: null,

    // @type {Function}
    onEditable: null,

    // @type {Function}
    onError: K,

    // @type {Function}
    onModeChange: K,

    // @type {Boolean}
    escapeUnicode: false,

    // @type {Boolean}
    history: true,

    // @type {String}
    mode: 'tree',

    /**
     * Note: unless overridden, this array is shared across all instances of this
     * component.
     *
     * @type {Array<String>}
     */
    modes: ['tree', 'view', 'form', 'code', 'text'],

    // @type {String|undefined}
    name: undefined,

    /**
     * @type {String|undefined}
     */
    normalizedName: _ember['default'].computed('name', function () {
      return this.get('name') || undefined;
    }),

    // @type {Object}
    schema: null,

    // @type {Boolean}
    search: true,

    // @type {Number}
    indentation: 2,

    // @type {String}
    theme: 'ace/theme/jsoneditor',

    // @type {Function}
    onChange: K,

    /**
     * Component state.
     */

    layout: _emberJsonEditorForComponentsJsonEditorForTemplate['default'],
    classNames: ['json-editor-for'],
    _isTyping: false,

    // @type {JSONEditor}
    editor: null,

    /**
     * Callbacks.
     */

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      // get options
      var options = this.getProperties(['ace', 'ajv', 'onEditable', 'onError', 'onModeChange', 'escapeUnicode', 'history', 'mode', 'modes', 'name', 'schema', 'search', 'indentation', 'theme']);

      // filter out null values
      Object.keys(options).forEach(function (key) {
        var value = options[key];
        if (value === null) delete options[key];
      });

      // jsoneditor dislikes empty name strings, but undefined is fine
      options.name = this.get('normalizedName');

      options.onChange = function () {
        run.debounce(_this, _this.onChangeWrapper, 150);
      };

      // make editor
      this.editor = new _jsoneditor['default'](this.element, options, this.get('json'));
    },

    onChangeWrapper: function onChangeWrapper() {
      // Set to false in `jsonChanged` observer.
      this.set('_isTyping', true);

      var editor = this.get('editor');
      try {
        this.get('onChange')(editor.get());
      } catch (err) {
        // `editor.get()` throws on invalid JSON.

        // if the invalid json was an empty string, we can handle that
        if (!editor.getText()) this.get('onChange')({});

        // otherwise, let the user handle it
        else this.get('onError')(err, editor.getText());
      }
    },

    jsonChanged: observer('json', function () {
      // Only update json if it was changed programmatically.
      if (!this.get('_isTyping')) this.editor.set(this.get('json'));

      // See `onChangeWrapper`.
      this.set('_isTyping', false);
    }),

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.editor.setName(this.get('normalizedName'));
    },

    willDestroyElement: function willDestroyElement() {
      this.editor.destroy();
      this.editor = null;
    },

    actions: {
      collapseAll: function collapseAll() {
        this.editor.collapseAll();
      },

      expandAll: function expandAll() {
        this.editor.expandAll();
      },

      focus: function focus() {
        this.editor.focus();
      },

      getJSON: function getJSON(cb) {
        if (!this.editor || !this.editor.get) return;
        cb(this.editor.get());
      }
    }
  });

  JSONEditorFor.reopenClass({
    positionalParams: ['json']
  });

  exports['default'] = JSONEditorFor;
});