define('ember-light-table/classes/Column', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _get = function get(_x2, _x3, _x4) {
    var _again = true;_function: while (_again) {
      var object = _x2,
          property = _x3,
          receiver = _x4;_again = false;if (object === null) object = Function.prototype;var desc = Object.getOwnPropertyDescriptor(object, property);if (desc === undefined) {
        var parent = Object.getPrototypeOf(object);if (parent === null) {
          return undefined;
        } else {
          _x2 = parent;_x3 = property;_x4 = receiver;_again = true;desc = parent = undefined;continue _function;
        }
      } else if ('value' in desc) {
        return desc.value;
      } else {
        var getter = desc.get;if (getter === undefined) {
          return undefined;
        }return getter.call(receiver);
      }
    }
  };

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
      throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
    }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;
  var emberArray = _ember['default'].A;

  /**
   * @module Table
   * @class Column
   */

  var Column = (function (_Ember$Object$extend) {
    _inherits(Column, _Ember$Object$extend);

    /**
     * @class Column
     * @constructor
     * @param {Object} options
     */

    function Column() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      _classCallCheck(this, Column);

      if (options instanceof Column) {
        return options;
      }

      _get(Object.getPrototypeOf(Column.prototype), 'constructor', this).call(this);
      this.setProperties(options);

      if (!isEmpty(options.subColumns)) {
        this.set('subColumns', emberArray(options.subColumns.map(function (sc) {
          return new Column(sc);
        })));
      }
    }

    return Column;
  })(_ember['default'].Object.extend({
    /**
     * @property hideable
     * @type {Boolean}
     * @default true
     */
    hideable: true,

    /**
     * @property hidden
     * @type {Boolean}
     * @default false
     */
    hidden: false,

    /**
     * If true, this column has been hidden due to the responsive behavior
     * @property responsiveHidden
     * @type {Boolean}
     * @default false
     */
    responsiveHidden: false,

    /**
     * @property ascending
     * @type {Boolean}
     * @default true
     */
    ascending: true,

    /**
     * @property sortable
     * @type {Boolean}
     * @default true
     */
    sortable: true,

    /**
     * @property resizable
     * @type {Boolean}
     * @default false
     */
    resizable: false,

    /**
     * @property sorted
     * @type {Boolean}
     * @default false
     */
    sorted: false,

    /**
     * Column header label
     * @property label
     * @type {String}
     * @default ''
     */
    label: '',

    /**
     * Text alignment. Possible values are ['left', 'right', 'center']
     * @property align
     * @type {String}
     * @default 'left'
     */
    align: 'left',

    /**
     * An array of sub columns to be grouped together
     * @property subColumns
     * @type {Array}
     * @optional
     */
    subColumns: null,

    /**
     * An array of media breakpoints that determine when this column will be shown
     *
     * If we have the following breakpoints defined in `app/breakpoints.js`:
     *
     * - mobile
     * - tablet
     * - desktop
     *
     * And we want to show this column only for tablet and desktop media, the following
     * array should be specified: `['tablet', 'desktop']`.
     *
     * If this property is `null`, `undefined`, or `[]`, then this column will always
     * be shown, regardless of the current media type.
     *
     * @property breakpoints
     * @type {Array}
     * @optional
     */
    breakpoints: null,

    /**
     * Type of column component
     *
     * You can create your own column types by running the blueprint:
     * `ember g column-type my-column-type`
     *
     * This will generate a component for you which represents the `<th>`
     * element for the column. If you want to apply custom actions to the `th`,
     * or do some custom styling of the `th` with classNameBindings, all of that is
     * available to you in this component.
     *
     * You can then specify the custom type you created as a string here, to use it.
     *
     *
     * @property type
     * @type {String}
     * @default 'base'
     */
    type: 'base',

    /**
     * Type of cell component
     *
     * You can create your own cell types by running the blueprint:
     * `ember g cell-type my-cell-type`
     *
     * This will generate a component for you which represents the `<td>`
     * cells in the column. If you want to apply custom actions to the `td`,
     * or do some custom styling of the `td` with classNameBindings, all of that is
     * available to you in this component.
     *
     * You can then specify the custom type you created as a string here, to use it.
     *
     * @property cellType
     * @type {String}
     * @default 'base'
     */
    cellType: 'base',

    /**
     * Component name for the column
     * @property component
     * @type {String}
     * @optional
     */
    component: null,

    /**
     * Component name for the column cells. This component is automatically passed row,
     * column, and value variables, and you can specify a valuePath to set what property
     * the value is set to.
     * @property cellComponent
     * @type {String}
     * @optional
     */
    cellComponent: null,

    /**
     * @property valuePath
     * @type {String}
     */
    valuePath: null,

    /**
     * @property width
     * @type {String}
     */
    width: null,

    /**
     * Class names to be applied to header and footer cells of this column
     *
     * @property classNames
     * @type {String | Array}
     */
    classNames: null,

    /**
     * Class names to be applied to all cells of this column
     *
     * @property cellClassNames
     * @type {String | Array}
     */
    cellClassNames: null,

    /**
     * A format function used to calculate a cell's value. This method will be passed
     * the raw value if `valuePath` is specified.
     *
     * @property format
     * @type {Function}
     */
    format: null,

    /**
     * True if `hidden` or `responsiveHidden` is true.
     * @property isHidden
     * @type {Boolean}
     */
    isHidden: computed.or('hidden', 'responsiveHidden').readOnly(),

    /**
     * @property isGroupColumn
     * @type {Boolean}
     * @private
     */
    isGroupColumn: computed.notEmpty('subColumns').readOnly(),

    /**
     * @property isVisibleGroupColumn
     * @type {Boolean}
     * @private
     */
    isVisibleGroupColumn: computed('visibleSubColumns.[]', 'isHidden', function () {
      return !isEmpty(this.get('visibleSubColumns')) && !this.get('isHidden');
    }).readOnly(),

    /**
     * @property visibleSubColumns
     * @type {Array}
     * @private
     */
    visibleSubColumns: computed('subColumns.[]', 'subColumns.@each.isHidden', 'isHidden', function () {
      var subColumns = this.get('subColumns');
      return isEmpty(subColumns) || this.get('isHidden') ? [] : subColumns.filterBy('isHidden', false);
    }).readOnly()
  }));

  exports['default'] = Column;
});