define('ember-json-schema-document/models/document', ['exports', 'ember', 'ember-json-schema-document/models/schema', 'ember-json-schema-document/utils/build-default-value-for-type', 'ember-json-schema-document/utils/check-validity', 'ember-json-schema-document/models/value-proxy'], function (exports, _ember, _emberJsonSchemaDocumentModelsSchema, _emberJsonSchemaDocumentUtilsBuildDefaultValueForType, _emberJsonSchemaDocumentUtilsCheckValidity, _emberJsonSchemaDocumentModelsValueProxy) {
  'use strict';

  var _bind = Function.prototype.bind;
  var _slice = Array.prototype.slice;

  var _get = function get(_x3, _x4, _x5) {
    var _again = true;_function: while (_again) {
      var object = _x3,
          property = _x4,
          receiver = _x5;_again = false;if (object === null) object = Function.prototype;var desc = Object.getOwnPropertyDescriptor(object, property);if (desc === undefined) {
        var parent = Object.getPrototypeOf(object);if (parent === null) {
          return undefined;
        } else {
          _x3 = parent;_x4 = property;_x5 = receiver;_again = true;desc = parent = undefined;continue _function;
        }
      } else if ('value' in desc) {
        return desc.value;
      } else {
        var getter = desc.get;if (getter === undefined) {
          return undefined;
        }return getter.call(receiver);
      }
    }
  };

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _inherits(subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
      throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
    }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var uuid = 0;

  var Document = (function () {
    _createClass(Document, null, [{
      key: 'build',
      value: function build(schema, baseType) {
        if (baseType === 'array') {
          return new (_bind.apply(ArrayDocument, [null].concat(_slice.call(arguments))))();
        } else if (baseType === 'object') {
          return new (_bind.apply(ObjectDocument, [null].concat(_slice.call(arguments))))();
        } else {
          throw new Error('What are you doing here?');
        }
      }
    }]);

    function Document(schema, baseType) {
      _classCallCheck(this, Document);

      if (!schema) {
        throw new Error('You must provide a Schema instance to the Document constructor.');
      }

      this._schema = schema;
      this._baseType = baseType;
      this._values = (0, _emberJsonSchemaDocumentUtilsBuildDefaultValueForType['default'])(this._baseType);
      this._uuid = 'document-' + baseType + '-' + ++uuid;
    }

    _createClass(Document, [{
      key: 'dump',
      value: function dump() {
        return this._values.serialize();
      }
    }, {
      key: 'load',
      value: function load() {
        throw new Error('Document#load must be overridden in the type specific base class');
      }
    }, {
      key: 'toJSON',
      value: function toJSON() {
        _ember['default'].deprecate('Using Document#toJSON is deprecated, please use Document#dump instead.', false, { id: 'ember-json-schema-document.document.toJSON', until: '0.1.0' });

        return this.dump.apply(this, arguments);
      }
    }]);

    return Document;
  })();

  exports['default'] = Document;

  var ArrayDocument = (function (_Document) {
    _inherits(ArrayDocument, _Document);

    function ArrayDocument() {
      _classCallCheck(this, ArrayDocument);

      _get(Object.getPrototypeOf(ArrayDocument.prototype), 'constructor', this).apply(this, arguments);

      this._documents = _ember['default'].A();
      this._uuidIndexes = {};
    }

    _createClass(ArrayDocument, [{
      key: '_buildDocumentInstance',
      value: function _buildDocumentInstance() {
        // TODO: handle array of arrays (WAT?)
        var schema = new _emberJsonSchemaDocumentModelsSchema['default'](this._schemaItems);
        var document = schema.buildDocument();

        return document;
      }
    }, {
      key: 'load',
      value: function load(items) {
        var _this = this;

        if (!Array.isArray(items)) {
          throw new Error('You must pass an array to `load` for array-based documents');
        }

        items.forEach(function (item) {
          _this.addItem(item);
        });
      }
    }, {
      key: 'dump',
      value: function dump() {
        var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

        var values = this._values;

        if (params.excludeInvalid) {
          values = this.validValues();
        }

        return values.map(function (item) {
          return item.serialize();
        });
      }
    }, {
      key: 'validValues',
      value: function validValues() {
        var _this2 = this;

        return this._values.filter(function (value, index) {
          var document = _this2._documents[index];
          return document.isValid;
        });
      }
    }, {
      key: 'addItem',
      value: function addItem() {
        var _this3 = this;

        var item = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

        if (this._baseType !== 'array') {
          throw new Error('You can only call `addItem` on documents with a base object of `array`.');
        }

        var document = this._buildDocumentInstance();
        document.load(item);

        _ember['default'].run(function () {
          _this3._values.pushObject(document._values);
          _this3._documents.pushObject(document);
        });

        this._uuidIndexes[document._uuid] = this._documents.length - 1;

        return document;
      }
    }, {
      key: 'getItem',
      value: function getItem(index) {
        return this._documents[index];
      }
    }, {
      key: 'removeItem',
      value: function removeItem(index) {
        var document = this.getItem(index);

        this._documents.removeAt(index);
        this._values.removeAt(index);

        this._uuidIndexes[document._uuid] = undefined;
      }
    }, {
      key: 'removeObject',
      value: function removeObject(item) {
        var index = this._uuidIndexes[item._uuid];

        this.removeItem(index);
      }
    }, {
      key: 'allItems',
      value: function allItems() {
        return this._documents.slice();
      }
    }, {
      key: '_schemaItems',
      get: function get() {
        return this._schema._schema.items;
      }
    }, {
      key: 'values',
      get: function get() {
        return this._documents;
      }
    }]);

    return ArrayDocument;
  })(Document);

  exports.ArrayDocument = ArrayDocument;

  var ObjectDocument = (function (_Document2) {
    _inherits(ObjectDocument, _Document2);

    function ObjectDocument(schema, baseType, data) {
      _classCallCheck(this, ObjectDocument);

      _get(Object.getPrototypeOf(ObjectDocument.prototype), 'constructor', this).apply(this, arguments);

      this._valueProxies = Object.create(null);
      this._propertyUpdate = 0;

      if (data) {
        this.load(data);
      }
    }

    _createClass(ObjectDocument, [{
      key: 'load',
      value: function load(data) {
        var properties = Object.keys(data);
        for (var i = 0, l = properties.length; i < l; i++) {
          var propertyName = properties[i];
          var proxy = this._valueProxyFor(propertyName);
          proxy.value = data[propertyName];
        }

        this._values.notifyPropertyChange('didLoad');
      }
    }, {
      key: '_valueProxyFor',
      value: function _valueProxyFor(path) {
        return this._valueProxies[path] = this._valueProxies[path] || _emberJsonSchemaDocumentModelsValueProxy['default'].build(this, path);
      }
    }, {
      key: 'set',
      value: function set(propertyPath, value) {
        var _this4 = this;

        if (value === undefined) {
          throw new Error('You must provide a value as the second argument to `.set`');
        }

        var initialValue = this.values.get(propertyPath);
        var proxy = this._valueProxyFor(propertyPath);
        proxy.value = value;

        if (initialValue !== value) {
          _ember['default'].run(function () {
            _this4.values.set(propertyPath, value);
            _this4.values.set('_propertyUpdate', _this4._propertyUpdate++);
          });
        }
      }
    }, {
      key: 'get',
      value: function get(propertyPath) {
        return this._valueProxyFor(propertyPath).value;
      }
    }, {
      key: 'validValuesFor',
      value: function validValuesFor(propertyPath) {
        return this._valueProxyFor(propertyPath)._property.validValues;
      }
    }, {
      key: 'properties',
      get: function get() {
        return this._schema.properties;
      }
    }, {
      key: 'values',
      get: function get() {
        return this._values;
      }
    }, {
      key: 'isValid',
      get: function get() {
        return (0, _emberJsonSchemaDocumentUtilsCheckValidity['default'])(this, this._values);
      }
    }, {
      key: 'required',
      get: function get() {
        return this._schema.required;
      }
    }]);

    return ObjectDocument;
  })(Document);

  exports.ObjectDocument = ObjectDocument;
});