define('ember-json-schema-document/models/document-object', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Object.extend({
    serialize: function serialize() {
      return this.serializeHash(this);
    },

    serializeHash: function serializeHash(hash) {
      var newAttrs = {};
      var excludeMatch = /^_/ig;

      for (var key in hash) {
        if (hash.hasOwnProperty(key) && !excludeMatch.test(key)) {
          var val = hash[key];

          if (typeof val === 'undefined' || val === null) {
            continue;
          }

          if (Array.isArray(val)) {
            val = val;
          } else if (typeof val === 'object') {
            val = this.serializeHash(val);
          } else if (typeof val.serialize === 'function') {
            val = val.serialize();
          }

          newAttrs[key] = val;
        }
      }
      return newAttrs;
    }
  });
});