define('ember-functional-helpers/helpers/w', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.w = w;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var helper = _ember['default'].Helper.helper;
  var toWords = _ember['default'].String.w;

  function w(_ref) {
    var _ref2 = _toArray(_ref);

    var wordStrings = _ref2;

    return wordStrings.map(toWords).reduce(function (words, moreWords) {
      return words.concat(moreWords);
    });
  }

  exports['default'] = helper(w);
});