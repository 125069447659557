define('ember-json-schema-views/components/schema-field-radio/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var DEFAULT_TRUE_LABEL = 'True';
  exports.DEFAULT_TRUE_LABEL = DEFAULT_TRUE_LABEL;

  var DEFAULT_FALSE_LABEL = 'False';

  exports.DEFAULT_FALSE_LABEL = DEFAULT_FALSE_LABEL;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['schema-field-component', 'schema-field-radio'],
    init: function init() {
      this._super.apply(this, arguments);
      var key = this.get('key');
      var document = this.get('document');
      var defaultValue = this.get('property.default');
      var initialValue = null;
      var documentValue = document.get(key);

      if (typeof defaultValue !== 'undefined') {
        initialValue = defaultValue;
      }

      if (typeof documentValue !== 'undefined') {
        initialValue = documentValue;
      }

      if (initialValue !== null) {
        this.set('value', initialValue);
        document.set(key, initialValue);
      }
    },

    trueLabel: _ember['default'].computed('property.displayProperties.labels.trueLabel', function () {
      return this.get('property.displayProperties.labels.trueLabel') || DEFAULT_TRUE_LABEL;
    }),

    falseLabel: _ember['default'].computed('property.displayProperties.labels.falseLabel', function () {
      return this.get('property.displayProperties.labels.falseLabel') || DEFAULT_FALSE_LABEL;
    }),

    actions: {
      changed: function changed() {
        var document = this.get('document');
        var key = this.get('key');
        var value = this.$('input[type="radio"]:checked').val();

        if (value && value.toLowerCase() === 'true') {
          value = true;
        } else {
          value = false;
        }

        document.set(key, value);
        this.set('value', value);
        this.sendAction('changed', value);
      }
    }
  });
});