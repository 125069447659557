define('ember-cli-g-maps/components/g-map-polygon/component', ['exports', 'ember-metal/get', 'ember-metal/set', 'ember-cli-g-maps/components/g-map-child', 'ember-cli-g-maps/factories/map-poly', 'ember-cli-g-maps/components/g-map-polygon/factory', 'ember-cli-g-maps/mixins/is-test', 'ember-cli-g-maps/configuration'], function (exports, _emberMetalGet, _emberMetalSet, _emberCliGMapsComponentsGMapChild, _emberCliGMapsFactoriesMapPoly, _emberCliGMapsComponentsGMapPolygonFactory, _emberCliGMapsMixinsIsTest, _emberCliGMapsConfiguration) {
  'use strict';

  var _component;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
      obj[key] = value;
    }return obj;
  }

  var EVENTS = _emberCliGMapsConfiguration['default'].googleMapCircle.events;
  var BOUND_OPTIONS = _emberCliGMapsConfiguration['default'].googleMapCircle.boundOptions;

  var googleMapScope = _emberCliGMapsConfiguration['default'].googleMap.scope;
  var googleMapsInstanceScope = _emberCliGMapsConfiguration['default'].googleMapCircle.scope;

  /*
   * Generate an instance of a map point component
   * as the g-map-polygon component class
   */
  exports['default'] = _emberCliGMapsComponentsGMapChild['default'].extend(_emberCliGMapsMixinsIsTest['default'], (0, _emberCliGMapsFactoriesMapPoly['default'])({
    bound: BOUND_OPTIONS,
    events: EVENTS,
    path: 'path',
    googleMapsInstanceScope: googleMapsInstanceScope,
    component: (_component = {}, _defineProperty(_component, googleMapsInstanceScope, null), _defineProperty(_component, 'insertGoogleMapInstance', function insertGoogleMapInstance() {
      return this._super.apply(this, arguments);
    }), _defineProperty(_component, 'insertedGoogleMapCanvas', function insertedGoogleMapCanvas(map, options) {
      this._super.apply(this, arguments);

      /*
       * Set path to a triangle in map center
       */
      if (!options.path) {
        options.path = makeTrianglePath((0, _emberMetalGet['default'])(map, 'center'));
      }

      var polygon = (0, _emberMetalSet['default'])(this, googleMapsInstanceScope, (0, _emberCliGMapsComponentsGMapPolygonFactory['default'])(map.content, options));

      /*
       * Some test helpers require access to the polygon instance
       */
      if ((0, _emberMetalGet['default'])(this, '_isTest')) {
        var canvas = map.content.getDiv();
        canvas.__GOOGLE_MAP_POLYGONS__ = canvas.__GOOGLE_MAP_POLYGONS__ || [];
        canvas.__GOOGLE_MAP_POLYGONS__.push(polygon.content);
      }
    }), _defineProperty(_component, 'willDestroyElement', function willDestroyElement() {
      this._super.apply(this, arguments);

      if ((0, _emberMetalGet['default'])(this, '_isTest')) {
        var canvas = (0, _emberMetalGet['default'])(this, googleMapScope).content.getDiv();
        var polygon = (0, _emberMetalGet['default'])(this, googleMapsInstanceScope);

        canvas.__GOOGLE_MAP_POLYGONS__.splice(canvas.__GOOGLE_MAP_POLYGONS__.indexOf(polygon.content), 1);
      }
    }), _component)
  }));

  /**
   * @param  {Object} latLng  Starting point
   * @param  {Number} offset  Path offset
   * @return {Array}          Polygon path
   * Draw a triangle around a given lat, lng coordinate
   */
  function makeTrianglePath(_ref) {
    var lat = _ref.lat;
    var lng = _ref.lng;
    var offset = arguments.length <= 1 || arguments[1] === undefined ? 0.5 : arguments[1];

    return [{ lat: lat + offset, lng: lng }, { lat: lat - offset, lng: lng - offset }, { lat: lat - offset, lng: lng + offset }];
  }

  /**
   * @type {Ember.ObjectProxy}
   * Proxy wrapper for the Google Map Marker instance
   */

  /**
   * Required for g-map-child Component
   */

  /**
   * @param {google.maps.Map}  Google Map
   * @param {Object} options
   * Method invoked after Google Maps libraries have loaded
   * and the the Google Map canvas has instantiated
   */

  /*
   * Remove any polygon instances from Google Map canvas
   */
});