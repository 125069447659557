define('ember-json-schema-document/utils/build-default-value-for-type', ['exports', 'ember', 'ember-json-schema-document/models/document-object'], function (exports, _ember, _emberJsonSchemaDocumentModelsDocumentObject) {
  'use strict';

  exports['default'] = buildDefaultValueForType;

  function buildDefaultValueForType(type) {
    switch (type) {
      case 'object':
        return _emberJsonSchemaDocumentModelsDocumentObject['default'].create({});
      case 'array':
        return _ember['default'].A();
      default:
        return undefined;
    }
  }
});