define('ember-functional-helpers/helpers/toggle', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports.toggle = toggle;

  var helper = _ember['default'].Helper.helper;
  var get = _ember['default'].get;
  var set = _ember['default'].set;

  function toggle(_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var obj = _ref2[0];
    var prop = _ref2[1];

    return function () {
      set(obj, prop, !get(obj, prop));
    };
  }

  exports['default'] = helper(toggle);
});