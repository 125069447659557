define('ember-cli-stylist/helpers/classy', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.classy = classy;

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var debug = _ember['default'].debug;
  // jshint ignore:line

  function classy(params /*, hash*/) {
    var _this = this;

    return params ? _ember['default'].String.htmlSafe(params.filter(function (f) {
      return f && get(_this, f);
    }).join(' ')) : '';
  }

  exports['default'] = _ember['default'].Helper.helper(classy);
});