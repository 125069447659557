define('ember-functional-helpers/helpers/pipe', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.pipe = pipe;

  var helper = _ember['default'].Helper.helper;
  var typeOf = _ember['default'].typeOf;

  function pipe() {
    var actions = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

    return function () {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return actions.reduce(function (acc, curr) {
        if (typeOf(acc) === 'undefined') {
          return curr.apply(undefined, args);
        }

        return curr.apply(undefined, [acc].concat(args));
      }, undefined);
    };
  }

  exports['default'] = helper(pipe);
});