define('ember-json-schema-views/components/each-property/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.getPropertyInputType = getPropertyInputType;

  function getPropertyInputType(property) {
    if (property.type === 'array') {
      return 'checkbox';
    }

    if (property.validValues && Array.isArray(property.validValues)) {
      return 'select';
    }

    if (property.type === 'boolean') {
      if (_ember['default'].get(property, 'displayProperties.useToggle')) {
        return 'toggle';
      } else {
        return 'radio';
      }
    }

    return 'text';
  }

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    recursive: true,
    propertyCollection: _ember['default'].computed('properties.[]', function () {
      var _getProperties = this.getProperties('properties', 'recursive');

      var properties = _getProperties.properties;
      var recursive = _getProperties.recursive;

      var propertyKeys = Object.keys(properties);

      return propertyKeys.map(function (key) {
        var property = properties[key];
        var showChildProperties = recursive && property.properties;

        return { key: key, property: property, type: getPropertyInputType(property),
          showChildProperties: showChildProperties, childProperties: property.properties };
      });
    })
  });
});