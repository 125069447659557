define('ember-json-schema-views/components/schema-field-toggle/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var DEFAULT_SIZE = 'small';
  exports.DEFAULT_SIZE = DEFAULT_SIZE;

  var DEFAULT_SHOW_LABELS = false;
  exports.DEFAULT_SHOW_LABELS = DEFAULT_SHOW_LABELS;

  var DEFAULT_TRUE_LABEL = 'True';
  exports.DEFAULT_TRUE_LABEL = DEFAULT_TRUE_LABEL;

  var DEFAULT_FALSE_LABEL = 'False';

  exports.DEFAULT_FALSE_LABEL = DEFAULT_FALSE_LABEL;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['schema-field-component', 'schema-field-toggle'],
    init: function init() {
      this._super.apply(this, arguments);

      var key = this.get('key');
      var document = this.get('document');
      var defaultValue = this.get('property.default');
      var initialValue = false;
      var documentValue = document.get(key);

      if (typeof defaultValue !== 'undefined') {
        initialValue = defaultValue;
      }

      if (typeof documentValue !== 'undefined') {
        initialValue = documentValue;
      }

      this.set('value', initialValue);

      if (initialValue !== documentValue) {
        document.set(key, initialValue);
      }
    },

    toggleSize: _ember['default'].computed('property.displayProperties.toggleSize', function () {
      return this.get('property.displayProperties.toggleSize') || DEFAULT_SIZE;
    }),

    showLabels: _ember['default'].computed('property.displayProperties.showLabels', function () {
      return this.get('property.displayProperties.showLabels') || DEFAULT_SHOW_LABELS;
    }),

    trueLabel: _ember['default'].computed('property.displayProperties.labels.trueLabel', function () {
      return (this.get('property.displayProperties.labels.trueLabel') || DEFAULT_TRUE_LABEL) + '::true';
    }),

    falseLabel: _ember['default'].computed('property.displayProperties.labels.falseLabel', function () {
      return (this.get('property.displayProperties.labels.falseLabel') || DEFAULT_FALSE_LABEL) + '::false';
    }),

    name: _ember['default'].computed.alias('key'),

    actions: {
      onToggle: function onToggle(toggleParams) {
        var newValue = toggleParams.newValue;

        var document = this.get('document');

        document.set(this.get('key'), newValue);
        this.set('value', newValue);
        this.sendAction('changed', newValue);
      }
    }
  });
});