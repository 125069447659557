define('ember-functional-helpers/helpers/intersect', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var emberArray = _ember['default'].A;
  var Helper = _ember['default'].Helper;
  var intersect = _ember['default'].computed.intersect;
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var guidFor = _ember['default'].guidFor;
  var isEmpty = _ember['default'].isEmpty;
  var observer = _ember['default'].observer;
  var set = _ember['default'].set;

  var idForArray = function idForArray(array) {
    return '__array-' + guidFor(array);
  };

  exports['default'] = Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref);

      var arrays = _ref2;

      set(this, 'arrays', arrays);

      return get(this, 'content');
    },

    arrays: null,
    content: null,

    arraysDidChange: observer('arrays.[]', function () {
      var _this = this;

      var arrays = get(this, 'arrays');

      var oldArrayKeys = get(this, 'arrayKeys') || [];
      var newArrayKeys = arrays.map(idForArray);

      var keysToRemove = oldArrayKeys.filter(function (key) {
        return newArrayKeys.indexOf(key) === -1;
      });

      keysToRemove.forEach(function (key) {
        return set(_this, key, null);
      });
      arrays.forEach(function (array) {
        return set(_this, idForArray(array), emberArray(array));
      });

      set(this, 'arrayKeys', newArrayKeys);

      if (isEmpty(arrays)) {
        defineProperty(this, 'content', []);
        return;
      }

      defineProperty(this, 'content', intersect.apply(undefined, _toConsumableArray(newArrayKeys)));
    }),

    contentDidChange: observer('content.[]', function () {
      this.recompute();
    })
  });
});