define('ember-json-schema-document/models/value-proxy', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var ValueProxy = (function () {
    _createClass(ValueProxy, null, [{
      key: 'build',
      value: function build(document, propertyPath) {
        var parts = propertyPath.split('.');
        var property = document;
        var values = document._values;
        var part = undefined;

        do {
          var _values = values;
          part = parts.shift();
          property = property.properties[part];

          if (parts.length > 0) {
            values = _values[part];
          }

          if (!values) {
            values = _values[part] = property.buildDefaultValue();
          }
        } while (parts.length > 0);

        return new ValueProxy(document, propertyPath, property, values, part);
      }
    }]);

    function ValueProxy(document, propertyPath, property, values, valuePath) {
      _classCallCheck(this, ValueProxy);

      this._document = document;
      this._propertyPath = propertyPath;
      this._property = property;
      this._values = values;
      this._valuePath = valuePath;
    }

    _createClass(ValueProxy, [{
      key: 'valueType',
      get: function get() {
        return this._property.type;
      }
    }, {
      key: 'value',
      set: function set(newValue) {
        var _this = this;

        if (!this._property) {
          throw new Error('You may not set a nonexistant field.');
        }

        _ember['default'].run(function () {
          _ember['default'].set(_this._values, _this._valuePath, newValue);
        });
      },
      get: function get() {
        if (!(this._valuePath in this._values)) {
          this.value = this._property.buildDefaultValue();
        }

        return _ember['default'].get(this._values, this._valuePath);
      }
    }]);

    return ValueProxy;
  })();

  exports['default'] = ValueProxy;
});