define("ember-json-schema-document/utils/check-validity", ["exports"], function (exports) {
  "use strict";

  exports["default"] = checkValidity;

  function checkValidity(property, object) {
    var required = property.required;
    var properties = property.properties;
    var validValues = property.validValues;

    for (var i = 0, l = required.length; i < l; i++) {
      var requiredPropertyName = required[i];
      var value = object && object[requiredPropertyName];

      // handles nested properties
      if (properties[requiredPropertyName] && !properties[requiredPropertyName].isValid(value)) {
        return false;
      }

      // handles normal values
      if (!value) {
        return false;
      }
    }

    // handles validValues / enum
    if (validValues && validValues.indexOf(object) === -1) {
      return false;
    }

    return true;
  }
});