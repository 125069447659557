define('ember-json-schema-views/components/property-options/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    showProperty: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var property = this.get('property.property');
      var document = this.get('document');

      if (!property.isDependentProperty) {
        return;
      }

      this.setProperties({
        isDependentProperty: true,
        dependsOnProperties: property.dependsOnProperties
      });

      property.dependsOnProperties.forEach(function (dependsOn) {
        _this.callback = _ember['default'].run.bind(_this, _this._onUpdatedMasterProperty);
        document.values.addObserver(dependsOn.property.documentPath, _this.callback);
      });

      document.values.addObserver('didLoad', this.callback);
      this._onUpdatedMasterProperty();
    },

    propertyOptions: _ember['default'].computed('showProperty', function () {
      var showProperty = this.get('showProperty');
      return { showProperty: showProperty };
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var property = this.get('property.property');
      var document = this.get('document');

      if (!property.isDependentProperty) {
        return;
      }

      property.dependsOnProperties.forEach(function (dependsOn) {
        document.values.removeObserver(dependsOn.property.documentPath);
      });

      document.values.removeObserver('didLoad', this.callback);
    },

    _onUpdatedMasterProperty: function _onUpdatedMasterProperty() {
      var property = this.get('property.property');
      var document = this.get('document');
      var dependencyCount = property.dependsOnProperties.length;

      var showProperty = property.dependsOnProperties.filter(function (dependsOn) {
        // `dependsOn.values` is an array of required values.  If the property's
        // current value is included in this array, it is now required
        var currentValue = document.get(dependsOn.property.documentPath);

        if (dependsOn.property.type === 'array') {
          // For array types, we are checking to see if any of the current values
          // are included in `dependsOn.values`.
          return Array.isArray(currentValue) && currentValue.filter(function (value) {
            return dependsOn.values.indexOf(value) > -1;
          }).length > 0;
        } else {
          return dependsOn.values.indexOf(currentValue) > -1;
        }
      }).length === dependencyCount;

      this.setProperties({ showProperty: showProperty });

      if (showProperty) {
        this._setDefaultValue();
      } else {
        document.set(property.documentPath, null);
      }
    },

    _setDefaultValue: function _setDefaultValue() {
      var document = this.get('document');
      var property = this.get('property.property');
      var defaultValue = this.get('property.default');
      var value = undefined;

      if (!_ember['default'].isNone(document.get(property.documentPath))) {
        return;
      }

      if (_ember['default'].isNone(defaultValue)) {
        value = ({ 'array': [], 'string': '', 'object': {} })[property.type];
      } else {
        value = defaultValue;
      }

      if (!_ember['default'].isNone(value)) {
        document.set(property.documentPath, value);
      }
    }
  });
});